import React, { useEffect, useState } from "react";
import "./sales-form.styles.scss";
import {
  Alert,
  Button,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import Logo from "../../assets/logo.webp";
import { MobileDatePicker, TimePicker } from "@mui/lab";
import moment from "moment";
import DateAdapter from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import { useParams, useNavigate } from "react-router-dom";
import UploadComponent from "../../components/UploadComponent";
var defaultTimeValue;
var defaultDateValue;
var minTime = moment("2013-02-08 08");
var maxTime = moment("2013-02-08 21");

const rosterRegionMap = {
  1887155970: { region: "2017157425", activeFollowUp: "new_group32786" }, //sorkin
  1887155122: { region: "2017153709", activeFollowUp: "new_group35727" }, //koontz
  1887154163: { region: "2017151920", activeFollowUp: "new_group46809" }, //Gutierrez
  1810279099: { region: "1787721664", activeFollowUp: "new_group59816" }, //Asvestas
};

const SalesForm = () => {
  const defaultNameSelectValue = (
    <MenuItem key="0" value={"N/A"}>
      N/A
    </MenuItem>
  );
  const [NameSelectList, setNameSelectList] = React.useState([
    defaultNameSelectValue,
  ]);
  const params = useParams();
  const [itemID, setItemID] = React.useState(
    params.itemId ? params.itemId : ""
  );
  const [loadingText, setLoadingText] = React.useState("");
  const [repLabel, setrepLabel] = React.useState(<p></p>);
  const [dealStatus, setDealStatus] = React.useState("Closed Won");
  const [selectedDate, setValue] = useState(new Date());
  const [timeValue, setTimeValue] = React.useState(
    new Date("2020-01-01 12:00")
  );
  const [showDateTime, setShowDateTime] = React.useState(false);
  const [roofFieldsVisible, setRoofFieldsVisible] = React.useState(false);
  const [submitSuccess, setSubmitSuccess] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  let [clientEmailMap, setClientEmailMap] = React.useState({});

  const navigate = useNavigate();
  defaultTimeValue = moment(timeValue);
  defaultDateValue = moment(selectedDate);

  const requiredNumberRules = {
    required: "This field is required",
    pattern: {
      value: /^[0-9]\d*(\.\d+)?$/gm,
      message: "Please enter a valid number",
    },
  };
  const notRequiredNumberRules = {
    required: false,
    pattern: {
      value: /^[0-9]\d*(\.\d+)?$/gm,
      message: "Please enter a valid number",
    },
  };
  const [systemValidationRules, setSystemValidationRules] =
    React.useState(requiredNumberRules);

  //THIS IS THE OUTPUT WEBHOOK
  const onSubmit = (data) => {
    if (data.clientName == "N/A") {
      return;
    }
    if (showDateTime) {
      if (data.followUpTime === undefined) {
        data.followUpTime = defaultTimeValue;
      }
      if (data.followUpDate === undefined) {
        data.followUpDate = defaultDateValue;
      }
      data.followUpDate = data.followUpDate.format("MM/DD/YYYY");
      data.followUpTime = data.followUpTime.format("HH:mm");
    } else {
      delete data.followUpDate;
      delete data.followUpTime;
    }
    data.dealStatus = dealStatus;
    data.itemID = itemID;
    data.email = clientEmailMap[data.clientName];
    data.clientName = data.clientName.slice(0, -1); //Removing last ID number digit
    setSubmitSuccess(false);
    setSubmitting(true);
    const requestOptions = {
      method: "post",
      headers: { "Content-Type": "application/json" },

      body: JSON.stringify(data),
    };
    fetch(
      "https://hook.integromat.com/f57w9wygevrwxf16i3xaf89860qv9m9p",
      requestOptions
    ).then((res) => {
      if (res.ok) {
        setSubmitting(false);
        setSubmitSuccess(true);
        navigate("/submitSuccess");
      }
    });
  };

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    getValues,
    setValue: setFieldValue,
  } = useForm();

  useEffect(() => {
    if (itemID != "") MondayApiCallback();
  }, []);
  const OnRoofRadioChange = (event) => {
    setRoofFieldsVisible(event.target.value == "yes");
  };

  const MondayApiCallback = () => {
    var userFound = false;
    setLoadingText("Loading...");
    setrepLabel(<p>Getting sales rep info...</p>);
    var repID = "";
    var regionId = 0;
    var query = `query {  boards(ids:[2527161225]) {id items (ids:${itemID}) { name column_values{ title value } } } }`;
    fetch("https://api.monday.com/v2", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjEyODIyMzY0NCwidWlkIjoyNTA1NjIwOSwiaWFkIjoiMjAyMS0xMC0xMVQxODo0Nzo1Ni4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6OTk2MTc1OCwicmduIjoidXNlMSJ9.Lo3aJk-3s_XAy-u1vQWY77OvZPtR9BlEFMNefXAh7tI",
      },
      body: JSON.stringify({
        query: query,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        res.data.boards.forEach((board) => {
          board.items.forEach((item) => {
            setrepLabel(
              <p>
                <b>Welcome {item.name}</b>
              </p>
            );
            userFound = true;
            item.column_values.forEach((column) => {
              if (column.title == "User ID" || column.title == "Rep ID") {
                repID = column.value;
                repID = repID.substring(1, repID.length - 1);
                regionId = board.id;
              }
            });
          });
        });

        if (!userFound) {
          setrepLabel(<p className="error-message">Invalid sales rep id</p>);
          setLoadingText("");
          return;
        }

        var query = `query { boards (ids:2527162272){ groups (ids:["topics","new_group35727","new_group15044"]){ items{ name column_values{ title value } } } } }`;

        fetch("https://api.monday.com/v2", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjEyODIyMzY0NCwidWlkIjoyNTA1NjIwOSwiaWFkIjoiMjAyMS0xMC0xMVQxODo0Nzo1Ni4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6OTk2MTc1OCwicmduIjoidXNlMSJ9.Lo3aJk-3s_XAy-u1vQWY77OvZPtR9BlEFMNefXAh7tI",
          },
          body: JSON.stringify({
            query: query,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            var listOfDeals = [];
            var emailArray = [];
            res.data.boards.forEach((board) => {
              board.groups.forEach((group) => {
                group.items.forEach((item) => {
                  var dealFound = false;
                  var lastName = "";
                  var address = "";
                  var email = "";
                  item.column_values.forEach((column) => {
                    if (column.title == "Last Name") {
                      lastName = column.value;
                      if (lastName != null) {
                        lastName = lastName.replace('"', "").replace('"', "");
                      }
                    }
                    if (column.title == "Address") {
                      address = column.value;
                      if (address != null) {
                        address = address.substring(
                          address.indexOf(":") + 2,
                          address.lastIndexOf('"')
                        );
                      }
                    }
                    if (column.title == "Email") {
                      email = column.value;
                      if (email != null) {
                        email = email.substring(
                          email.indexOf(":") + 2,
                          email.indexOf('"', email.indexOf(":") + 2)
                        );
                      }
                    }
                    if (column.title == "Sales Rep") {
                      if (
                        column.value != null &&
                        column.value.includes(`"id":${repID}`)
                      )
                        dealFound = true;
                    }
                  });
                  if (dealFound) {
                    emailArray.push(email);
                    listOfDeals.push(
                      item.name + " " + lastName + " - " + address
                    );
                  }
                });
              });
            });
            NameSelectList.length = 0;
            clientEmailMap = {};
            for (let i = 0; i < listOfDeals.length; i++) {
              clientEmailMap[listOfDeals[i] + i] = emailArray[i];
              NameSelectList.push(
                <MenuItem key={listOfDeals[i] + i} value={listOfDeals[i] + i}>
                  {listOfDeals[i]}
                </MenuItem>
              );
            }
            setClientEmailMap(clientEmailMap);
            NameSelectList.push(defaultNameSelectValue);
            setNameSelectList(NameSelectList);
            setLoadingText("Loaded");
          });
      });
  };

  useEffect(() => {
    if (itemID.length == 10) {
      MondayApiCallback();
    } else if (itemID.length > 0) {
      setrepLabel(<p className="error-message">Invalid sales rep id</p>);
    }
  }, [itemID]);

  const followUpDateTime = (
    <div className="align-left">
      <Controller
        control={control}
        name="followUpDate"
        render={({
          field: { onChange, onBlur, value = selectedDate, ref },
        }) => (
          <LocalizationProvider dateAdapter={DateAdapter}>
            <MobileDatePicker
              disablePast
              label="Follow up date*"
              value={value}
              onChange={onChange}
              selected={value}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        )}
      />
      <Controller
        control={control}
        name="followUpTime"
        render={({ field: { onChange, onBlur, value = timeValue, ref } }) => (
          <LocalizationProvider dateAdapter={DateAdapter}>
            <TimePicker
              renderInput={(params) => <TextField {...params} />}
              label="Follow up time*"
              value={value}
              onChange={onChange}
              minTime={minTime}
              maxTime={maxTime}
              shouldDisableTime={(timeValue, clockType) => {
                if (clockType === "minutes" && timeValue % 15) {
                  return true;
                }
                return false;
              }}
            />
          </LocalizationProvider>
        )}
      />
    </div>
  );
  const shouldShowDateTime = () => {
    if (showDateTime) return followUpDateTime;
    else return;
  };

  return (
    <div className="first-form">
      <Paper className="form-background" elevation={9}>
        <div className="logo-container">
          <img className="logo" src={Logo} />
        </div>
        <form className="inner-form" onSubmit={handleSubmit(onSubmit)}>
          {repLabel}
          <Controller
            render={({ field }) => (
              <TextField
                label="Sales Rep ID*"
                error={errors?.itemID ? true : false}
                helperText={errors?.itemID?.message}
                {...field}
                value={itemID}
                onChange={(e) => {
                  setItemID(e.target.value);
                }}
              />
            )}
            name="itemID"
            control={control}
            defaultValue={itemID}
          />

          <div className="align-left">
            <FormLabel component="legend">Name &nbsp;</FormLabel>
            <Controller
              render={({ field }) => (
                <Select {...field}>{NameSelectList}</Select>
              )}
              name="clientName"
              control={control}
              defaultValue={"N/A"}
            />
            <p className="loading-label" component="legend">
              {" "}
              &nbsp;&nbsp; {loadingText}
            </p>
          </div>

          <div className="align-left">
            <FormLabel component="legend">Status &nbsp;</FormLabel>
            <Controller
              render={({ field }) => (
                <Select
                  {...field}
                  value={dealStatus}
                  onChange={(e) => {
                    setDealStatus(e.target.value);
                    if (e.target.value == "Closed Won")
                      setSystemValidationRules(requiredNumberRules);
                    else {
                      setSystemValidationRules(notRequiredNumberRules);
                      errors.systemSize = null;
                      errors.systemPPW = null;
                    }
                    setShowDateTime(e.target.value == "Follow Up");
                  }}
                >
                  <MenuItem value={"Closed Won"}>Closed Won</MenuItem>
                  <MenuItem value={"Closed Lost"}>Closed Lost</MenuItem>
                  <MenuItem value={"Appointment Ghosted"}>
                    Appointment Ghosted
                  </MenuItem>
                  <MenuItem value={"Follow Up"}>Follow Up</MenuItem>
                  <MenuItem value={"DQ/FC"}>DQ/FC</MenuItem>
                </Select>
              )}
              name="dealStatus"
              control={control}
              defaultValue={dealStatus}
              value={dealStatus}
            />
          </div>
          {dealStatus != "DQ/FC" && (
            <Controller
              render={({ field }) => (
                <TextField
                  label="Post Appointment Notes"
                  multiline
                  inputProps={{ style: { fontSize: 13 } }}
                  {...field}
                />
              )}
              name="postAppointmentNotes"
              control={control}
              defaultValue=""
            />
          )}
          {dealStatus == "DQ/FC" && (
            <Controller
              render={({ field }) => (
                <TextField
                  label="DQ/FC Reason"
                  multiline
                  inputProps={{ style: { fontSize: 13 } }}
                  {...field}
                />
              )}
              name="DQ/FCReason"
              control={control}
              defaultValue=""
            />
          )}
          {shouldShowDateTime()}
          {dealStatus == "Closed Won" && (
            <div className="closed-won-div">
              <div className="system-input-container">
                <Controller
                  render={({ field }) => (
                    <TextField
                      className="system-input"
                      label="System Size"
                      error={errors?.systemSize ? true : false}
                      helperText={errors?.systemSize?.message}
                      {...field}
                    />
                  )}
                  name="systemSize"
                  rules={systemValidationRules}
                  control={control}
                  defaultValue=""
                />
                <Controller
                  render={({ field }) => (
                    <TextField
                      className="system-input"
                      label="System PPW"
                      error={errors?.systemPPW ? true : false}
                      helperText={errors?.systemPPW?.message}
                      {...field}
                    />
                  )}
                  name="systemPPW"
                  rules={systemValidationRules}
                  control={control}
                  defaultValue=""
                />
              </div>
              <div className="align-left">
                <FormLabel component="legend">
                  Financier & Option &nbsp;
                </FormLabel>
                <Controller
                  render={({ field }) => (
                    <Select {...field}>
                      <MenuItem value={"N/A"}>N/A</MenuItem>
                      <MenuItem value={"Cash"}>Cash</MenuItem>
                      <MenuItem value={"Sunlight 20 year 1.49%"}>
                        Sunlight 20 year 1.49%
                      </MenuItem>
                      <MenuItem value={"Sunlight 25 year 1.99%"}>
                        Sunlight 25 year 1.99%
                      </MenuItem>
                      <MenuItem value={"Sunlight 25 year 2.99%"}>
                        Sunlight 25 year 2.99%
                      </MenuItem>
                      <MenuItem value={"Loanpal 25 year 2.99%"}>
                        Loanpal 25 year 2.99%
                      </MenuItem>
                    </Select>
                  )}
                  name="financeOption"
                  control={control}
                  defaultValue={"N/A"}
                />
              </div>

              <div className="align-left">
                <FormLabel component="legend">Is a new roof required</FormLabel>
              </div>
              <Controller
                render={({ field }) => (
                  <RadioGroup aria-label="Is a new roof required" {...field}>
                    <div className="align-left">
                      <FormControlLabel
                        onChange={OnRoofRadioChange}
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                      <FormControlLabel
                        onChange={OnRoofRadioChange}
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                    </div>
                  </RadioGroup>
                )}
                name="roofRequired"
                control={control}
                defaultValue={"no"}
              />

              {roofFieldsVisible && (
                <div>
                  {" "}
                  <div className="align-left">
                    <FormLabel component="legend">Roof type &nbsp;</FormLabel>
                    <Controller
                      render={({ field }) => (
                        <Select {...field}>
                          <MenuItem value={"Shingle"}>Shingle</MenuItem>
                          <MenuItem value={"Tiled (12 Cent Adder)"}>
                            Tiled (12 Cent Adder)
                          </MenuItem>
                          <MenuItem value={"Metal (12 Cent Adder)"}>
                            Metal (12 Cent Adder)
                          </MenuItem>
                          <MenuItem value={"Flat (12 Cent Adder)"}>
                            Flat (12 Cent Adder)
                          </MenuItem>
                        </Select>
                      )}
                      name="roofType"
                      control={control}
                      defaultValue={"Shingle"}
                    />
                  </div>
                  <div className="input-margin">
                    <Controller
                      render={({ field }) => (
                        <TextField
                          className="input-width"
                          label="Cost of the Roof"
                          error={errors?.roofCost ? true : false}
                          helperText={errors?.roofCost?.message}
                          {...field}
                        />
                      )}
                      name="roofCost"
                      rules={{
                        pattern: {
                          value: /^[$]?[0-9]\d*((\.|\,)\d+)?$/gm,
                          message: "Please enter a valid number",
                        },
                      }}
                      control={control}
                      defaultValue=""
                    />
                  </div>
                  <div className="input-margin">
                    <Controller
                      render={({ field }) => (
                        <TextField
                          className="input-width"
                          label="Roofing Notes"
                          multiline
                          rows={3}
                          error={errors?.roofingNotes ? true : false}
                          helperText={errors?.roofingNotes?.message}
                          {...field}
                        />
                      )}
                      name="roofingNotes"
                      control={control}
                      defaultValue=""
                    />
                  </div>
                  <br />
                  <Controller
                    render={({ field }) => (
                      <UploadComponent
                        label={"Upload Roofing Addendum"}
                        accept={"*"}
                        folder={"roofing-addendum"}
                        data={getValues().roofingAddendum}
                        setData={(f) => {
                          setFieldValue("roofingAddendum", f || null);
                        }}
                      />
                    )}
                    name="roofingAddendum"
                    control={control}
                    defaultValue={[]}
                  />
                  <br />
                  <Controller
                    render={({ field }) => (
                      <UploadComponent
                        label={"Photo of the property"}
                        accept={"*"}
                        folder={"property-photo"}
                        data={getValues().propertyPhoto}
                        setData={(f) => {
                          setFieldValue("propertyPhoto", f || null);
                        }}
                      />
                    )}
                    name="propertyPhoto"
                    control={control}
                    defaultValue={[]}
                  />
                  <br />
                </div>
              )}
            </div>
          )}
          {submitting && <Alert severity="info">Submitting form</Alert>}

          {submitSuccess && (
            <Alert severity="success">Thank you for updating the status</Alert>
          )}
          <Button variant="contained" disabled={submitting} type="submit">
            Update Status
          </Button>
        </form>
      </Paper>
    </div>
  );
};
export default SalesForm;
